import React, { useEffect, useState } from "react"
import Seo from "../components/seo"
import BrandHeading from "../components/brand-heading";
import CakeItemDetail from "../components/cake-item-detail";
import { Link, navigate } from "gatsby"
import { useCartItemsContext, useCartActionContext } from "../components/cart-context"
import useUpdateEffect from './../hooks/use-update-effect';
import { FormButton, FormInputNumber } from "../components/form-elements";
import { toCurrencyFormat } from "../helpers/currency-format-helper";
import useCost from "./../hooks/use-cost";

export default function Cart() {
    const cartItems = useCartItemsContext();
    const cartItemComponents = cartItems ? cartItems.map(x => <CartItem key={x.key} cake={x} />) : []
    const hasCartItem = cartItemComponents.length > 0;
    return (
        <>
            <Seo title="Cart" />
            <BrandHeading title="cart" />
            <section>
                {
                    hasCartItem ?
                        <table className="table-auto w-full">
                            <thead>
                                <tr>
                                    <th className="font-ovo text-xs uppercase text-left py-4 text-gray-800 tracking-widest">Item</th>
                                    <th className="font-ovo text-xs uppercase text-center py-4 text-gray-800 tracking-widest">Qty</th>
                                    <th className="font-ovo text-xs uppercase text-right py-4 text-gray-800 tracking-widest">Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="col-span-3 border border-b-1" />
                                {cartItemComponents}
                                <tr className="col-span-3 border border-b-1" />
                                <CartSubTotal />
                                <tr className="col-span-3 border border-b-1" />
                            </tbody>
                        </table>
                        :
                        <div className="text-center text-xs md:text-sm tracking-wider md:tracking-[.06em] text-pas-gray">Your cart is empty. <Link to="/cakes" className="text-pas-orange">You may check out our range of delicious cakes!</Link></div>
                }
                <div className="max-w-xs mx-auto my-8">
                    {hasCartItem ? <FormButton className="w-full mb-4" label={"CHECKOUT"} onClick={() => navigate(`/checkout-customer/`)} /> : <></>}
                    <FormButton className="w-full mb-4" label={hasCartItem ? "CONTINUE SHOPPING" : "SHOW NOW"} onClick={() => navigate(`/cakes/`)} isSecondary={true} />
                </div>

            </section>
        </>
    )
}



const CartItem = ({ cake }) => {
    const { updateCake } = useCartActionContext();
    const [quantity, setQuantity] = useState(() => cake.quantity)
    const updateQuantity = (event) => {
        const slicedValue = event().slice(0, 2);
        setQuantity(slicedValue);
    }

    const correctQuantity = () => {
        var validQuantity = parseInt(quantity);
        if (!quantity || validQuantity === NaN || validQuantity <= 0 || validQuantity > 10)
            setQuantity(() => cake.quantity);
    }
  
    const {getCakeAmount} = useCost();
    useUpdateEffect(() => {
        var validQuantity = (quantity) ? parseInt(quantity) : 1;
        if (validQuantity === NaN || validQuantity <= 0) validQuantity = 1;
        else if (validQuantity > 10) validQuantity = 10;
        updateCake({ ...cake, quantity: validQuantity });
    }, [quantity])

    return (
        <tr>
            <td className="text-left align-top"><CakeItemDetail cake={cake} /></td>
            <td className="align-top">
                <div className="w-10 md:w-16 text-center mx-auto py-2">
                    <FormInputNumber value={quantity} onBlur={correctQuantity} onChange={updateQuantity} min="1" max="10" maxLength="2" />
                </div>
            </td>
            <td className="text-right align-top">
                <div className="py-2 pt-5 flex flex-col justify-between font-ovo text-base md:text-lg text-gray-800">
                    <span>{toCurrencyFormat(getCakeAmount(cake))}</span>
                </div>
            </td>
        </tr>
    )
}

const CartSubTotal = () => {   
    const {subTotal} = useCost();
    return (
        <tr className="font-ovo text-base sm:text-lg text-gray-800 tracking-wider">
            <td>SUBTOTAL</td>
            <td></td>
            <td className="text-right py-8 pr-1">{toCurrencyFormat(subTotal)}</td>
        </tr>
    )
}